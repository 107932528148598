<template>
    <el-main>
        <el-form class="el-form-search" label-width="130px">
            <el-form-item label="订单编号：">
                <el-input size="small" v-model="ruleForm.ordersn" placeholder="请输入订单编号"></el-input>
            </el-form-item>
            <el-form-item label="预约人：">
                <el-input size="small" v-model="ruleForm.orderperson" placeholder="请输入预约人昵称"></el-input>
            </el-form-item>
            <el-form-item label="回收信息：">
                <el-input size="small" v-model="ruleForm.goods_name" placeholder="请输入回收物品分类"></el-input>
            </el-form-item>
            <el-form-item label="下单时间：">
                <el-date-picker v-model="ruleForm.time" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" size="small"></el-date-picker>
            </el-form-item>
            <el-form-item label="接单人：">
                <el-input size="small" v-model="ruleForm.takeorder" placeholder="请输入接单人姓名/手机号"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="30px">
                <el-button type="primary" size="small" @click="getShopList('search')">搜索</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-tabs v-model="activeName" type="card" @tab-click="getShopList(1)">
            <el-tab-pane v-for="(item, index) in activeList" :key="index" :label="item.name + `(${item.num})`"
                :name="item.post"></el-tab-pane>
        </el-tabs>
        <OrderTable :dataList="dataList" @updateList="updateList"></OrderTable>
        <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import OrderTable from './orderTable';
import Paging from '@/components/paging';
export default {
    components: {
        OrderTable,
        Paging,
    },
    data () {
        return {
            ruleForm: {
                ordersn: '',
                orderperson: '',
                goods_name: '',
                time: [],
                takeorder: '',
            },
            categoryList: [{ name: '全部', id: 0 }],
            activeName: 'all',
            activeList: [
                { name: '全部', value: 'order_all', num: 0, post: 'all' },
                { name: '待接单', value: 'order_wait', num: 0, post: '10' },
                { name: '待上门', value: 'orderdoor', num: 0, post: '20' },
                { name: '待核准', value: 'order_hz', num: 0, post: 'hz' },
                { name: '完成', value: 'order_finsh', num: 0, post: '50' },
                { name: '关闭', value: 'order_close', num: 0, post: 'close' },
            ],
            dataList: [],
            page: 1,
            rows: 10,
            total: 0,
        };
    },
    created () {
        if (this.$route.query.name) {
            this.ruleForm.orderperson = this.$route.query.name;
        }
        this.getOrderNum();
        this.getShopList();
    },
    methods: {
        updateList () {
            this.getOrderNum();
            this.getShopList();
        },
        getOrderNum () {
            this.$axios.post(this.$api.O2O.order.getTotal).then(res => {
                if (res.code === 0) {
                    this.activeList.map(item => {
                        item.num = res.result[item.value];
                    });
                }
            });
        },
        updateData (val, status) {
            if (status == 0) {
                this.rows = val;
            } else {
                this.page = val;
            }
            this.getShopList();
        },
        cancelSearch () {
            this.ruleForm = {
                ordersn: '',
                orderperson: '',
                goods_name: '',
                time: [],
                takeorder: '',
            };
            this.getShopList();
        },
        getShopList (style) {
            if (style) {
                this.page = 1;
            }
            let obj = {
                page: this.page,
                rows: this.rows,
            };
            if (this.activeName !== 'all') {
                obj.status = this.activeName;
            }
            if (this.ruleForm.ordersn) {
                obj.ordersn = this.ruleForm.ordersn;
            }
            if (this.ruleForm.orderperson) {
                obj.orderperson = this.ruleForm.orderperson;
            }
            if (this.ruleForm.goods_name) {
                obj.goods_name = this.ruleForm.goods_name;
            }
            if (this.ruleForm.takeorder) {
                obj.takeorder = this.ruleForm.takeorder;
            }
            if (this.time && this.time.length === 2) {
                obj.starttime = Math.floor(this.time[0] / 1000);
                obj.endtime = Math.floor(this.time[1] / 1000);
                if (obj.starttime === obj.endtime) {
                    obj.endtime = obj.starttime + 60 * 60 * 24 - 1;
                }
            }
            this.$axios.post(this.$api.O2O.order.list, obj).then(res => {
                if (res.code === 0) {
                    this.dataList = res.result.list;
                    this.total = res.result.total;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
}
</style>
